/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($, _c, undefined) {
	'use strict';

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.

	var Sage = {
		// All pages
		'common': {
			init: function () {
				// JavaScript to be fired on all pages
				var windowWidth = $(window).width();

				$('.hamburger').on('click', function () {
					$(this).toggleClass('is-active');
					$('.topnav').toggleClass('is-active');
					$('.menu-filter').toggleClass('active');
					$("body").toggleClass("noscroll");
				});

				$(window).resize(function () {
					if ($(window).width() != windowWidth) {
						windowWidth = $(window).width();
						$('.topnav').removeClass('is-active');
						$('.hamburger').removeClass('is-active');
						$('.menu-filter').removeClass('active');
						$("body").removeClass("noscroll");
					};
				});

				// Scroll To
				(function () {
					$('.scrollto').on('click', function (e) {
						e.preventDefault();
						var section = $(this).attr('href');
						$('html, body').animate({
							scrollTop: $(section).offset().top
						}, 1000);
					});
				})();

				// Form
				(function () {
					$('select').selectric();
				})();
				$('.select-home-box select').change(function(){
					var th = $(this).val();
					if (th != '' || th != 'undefined'){
						window.location.href = th;
					}
				});

				$('.search-bar .select li').click(function() {
				    $(this).parents('.select').find('span').text($(this).text());
				    return false;
                });
			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired

				$(".accordion-content").hide();
				$(".accordion-control").click(function (event) {
					event.preventDefault();

					var $control = $(this);
					var accordionContent = $control.attr('aria-controls');
					var isAriaExp = $control.attr('aria-expanded');
					$control.find('.control-icon').toggleClass("open");

					if (isAriaExp == "true") {
						$control.attr('aria-expanded', false);
					} else {
						$control.attr('aria-expanded', true);
					}

					var isAriaHid = $("#" + accordionContent).attr('aria-hidden');

					if (isAriaHid == "true") {
						$('#' + accordionContent).attr('aria-hidden', "false");
					} else {
						$('#' + accordionContent).attr('aria-hidden', "true");
					}
					$(this).siblings(".accordion-content").slideToggle();
				});

				var prevScrollNav = 0;

				$(window).on('scroll', function () {
					var scrollPosition = $(document).scrollTop();

					// Show/hide navbar

					if ($(window).scrollTop() > $('header.header').height()) {
						$('header .right').addClass('hide');
					} else {
						$('header .right').removeClass('hide');
					}

					if ($(window).scrollTop() >= prevScrollNav) {
						$('header .right').removeClass('show');
					} else {
						$('header .right').addClass('show');
					}

					prevScrollNav = $(window).scrollTop();

					// Move in red squares
					var squares = $('.red-square');
					squares.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 500) {
							$(this).addClass("active");
						}
					});

					// Fade in fifty-fity images
					var images = $('.fifty-fifty .image img');
					images.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 500) {
							$(this).addClass("active");
						}
					});

					// Move parallax items when they arrive on page
					$('.parallax-fast').each(function () {
						if ($(this).hasClass("parallax-immediate")) {
							var relativeScroll = scrollPosition;
						} else {
							var relativeScroll = scrollPosition - ($(this).offset().top - 300);
						}

						$(this).css('transform', 'translate3d(0, ' + relativeScroll * -0.25 + 'px, 0)');
					});

					$('.parallax-med').each(function () {
						if ($(this).hasClass("parallax-immediate")) {
							var relativeScroll = scrollPosition;
						} else {
							var relativeScroll = scrollPosition - ($(this).offset().top - 300);
						}

						$(this).css('transform', 'translate3d(0, ' + relativeScroll * -0.2 + 'px, 0)');
					});

					$('.parallax-slow').each(function () {
						if ($(this).hasClass("parallax-immediate")) {
							var relativeScroll = scrollPosition;
						} else {
							var relativeScroll = scrollPosition - ($(this).offset().top - 300);
						}

						$(this).css('transform', 'translate3d(0, ' + relativeScroll * -0.1 + 'px, 0)');
					});
				});

				$(window).resize(function() {
				    $('.j-stacker li.feed-item').each(function() {
				        $(this).css({'min-height': $(this).outerWidth()});
                    });
                });
                $(document).ajaxComplete(function() {
                    $(window).trigger('resize');
                });
			}
		},

		// Home page
		'page_template_template_home': {
			init: function () {

			},
			finalize: function () {

				$(window).on('scroll', function () {
					var scrollPosition = $(document).scrollTop();

					// Draw lines for headers w/ lines
					var lines = $('.line');
					lines.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 500) {
							$(this).addClass("active");
						}
					});

					// move in red boxes in "meet" sections
					var boxes = $('.meet .red-box');
					boxes.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 500) {
							$(this).addClass("active");
						}
					});

					// fade in images in "meet" sections
					var images = $('.meet .photo');
					images.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 500) {
							$(this).addClass("active");
						}
					});
				});

			}
		},


		// Landing page
		'page_template_template_landing': {
			init: function () {

			},
			finalize: function () {

				$(window).on('scroll', function () {
					var scrollPosition = $(document).scrollTop();

					// Draw lines for headers w/ lines
					var lines = $('.line');
					lines.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 500) {
							$(this).addClass("active");
						}
					});

					// move in red boxes in "meet" sections
					var boxes = $('.meet .red-box');
					boxes.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 500) {
							$(this).addClass("active");
						}
					});

					// fade in images in "meet" sections
					var images = $('.meet .photo');
					images.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 500) {
							$(this).addClass("active");
						}
					});
				});

			}
		},

		// Programs landing
		'page_template_template_programs_landing': {
			init: function () {

			},
			finalize: function () {
				$(window).on('scroll', function () {
					var scrollPosition = $(document).scrollTop();

					var texts = $('.text');
					texts.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 500) {
							$(this).addClass("active");
						}
					});

					if ($(".full-width-info .text").length >= 1) {
						if (scrollPosition >= ($(".full-width-info .text").offset().top) - 500) {
							$(this).addClass("active");
						}
					}

				});
			}
		},

		// Our Strategy
		'page_template_template_strategy': {
			init: function () {

			},
			finalize: function () {
				$(window).on('scroll', function () {
					var scrollPosition = $(document).scrollTop();

					var texts = $('.text');
					texts.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 500) {
							$(this).addClass("active");
						}
					});

					if ($(".full-width-info .text")) {
						if (scrollPosition >= ($(".full-width-info .text").offset().top) - 500) {
							$(this).addClass("active");
						}
					}

				});
			}
		},

		// Programs detail
		'page_template_template_programs_detail': {
			init: function () {

			},
			finalize: function () {
				$(window).on('scroll', function () {
					var scrollPosition = $(document).scrollTop();

					var texts = $('.text');
					texts.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 500) {
							$(this).addClass("active");
						}
					});

				});
			}
		},

		'single_program': {
		    init: function()
            {
                $('.search-bar').submit(function() {
                        return false;
                });

                $('.search-bar input').keyup(function() {
                    $('.people .person').addClass('hidden');

                    $('.people .person h4.name a:contains(' + $(this).val() + ')').parents('.person').removeClass('hidden');

                    if($('.people .person.hidden').length == $('.people .person').length) {
                        $('.empty-results').show();
                    } else {
                        $('.empty-results').hide();
                    }
                });

                $('.search-bar .filter .select ul li').bind('click', function() {
                    var currentFilterName = $(this).parents('.filter').data('name'),
                        value = $(this).attr('data-value');

                    $('.people .person').addClass(currentFilterName + '-hidden');

                    if(value == "") {
                        $('.people .person').removeClass(currentFilterName + '-hidden');
                    }

                    $('.people .person[data-' + currentFilterName + '*="' + value + '"]').removeClass(currentFilterName + '-hidden');

                    // $('.people .person:contains(' + $(this).attr('data-value') + ')').parents('.person').removeClass('hidden');

                    /*if($('.people .person.hidden').length == $('.people .person').length) {
                        $('.empty-results').show();
                    } else {
                        $('.empty-results').hide();
                    }*/
                });
            },
			finalize: function () {

				$(window).on('scroll', function () {
					var scrollPosition = $(document).scrollTop();

					var texts = $('.text');
					texts.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 500) {
							$(this).addClass("active");
						}
					});

				});
			}
		},

		// Get involved
		'page_template_template_get_involved': {
			init: function () {

			},
			finalize: function () {
				$(window).on('scroll', function () {
					var scrollPosition = $(document).scrollTop();

					var texts = $('.text');
					texts.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 500) {
							$(this).addClass("active");
						}
					});

				});
			}
		},

		// News landing
		'page_template_template_news_landing': {
			init: function () {
				$('#load-more-news').click(function () {
					$('.loading').show();
					$.post(adminAjaxUrl, {
						action: 'load_news',
                        s: $('.search-bar form input[name="s"]').val(),
                        category: $('.search-bar form input[name="category"]').val(),
						loadFrom: $(this).attr('data-load-page')
					}, function (response, textStatus, request) {

					    var hasMoreItems = request.getResponseHeader('X-HasMoreItems');

						$('.loading').hide();

						if (response == "") {
							$('#load-more-news').hide();
							return;
						}

						if($('#load-more-news').attr('data-load-page') > 0) {
                            $('section.news-list').append(response);
                        } else {
                            $('section.news-list').html(response);
                        }

                        if(hasMoreItems == 0) {
                            $('#load-more-news').hide();
                            return;
                        }

						$('#load-more-news').attr('data-load-page', parseInt($('#load-more-news').attr('data-load-page')) + 1);
					});
					return false;
				});

				$('.search-bar .filter .select ul li').click(function() {
				    $('.search-bar form input[name="category"]').val($(this).attr('data-value'));
				    $('.search-bar form').trigger('submit');

				    return false;
                });

                $('.search-bar form').submit(function() {
                    $('#load-more-news').attr('data-load-page', 0);
                    $('#load-more-news').trigger('click');
                    return false;
                });

                $(document).on('click', '.news-item', function() {
                    window.location.href = $('h3 > a', this).attr('href');
                });
			},
			finalize: function () {
				$(window).on('scroll', function () {
					var scrollPosition = $(document).scrollTop();

					var newsItems = $('.news-item');
					newsItems.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 700) {
							$(this).addClass("active");
						}
					});

				});

				$('#load-more-news').trigger('click');
			}
		},

		// Recent donors
		'page_template_template_recent_donors': {
			init: function () {

			},
			finalize: function () {
				$(window).on('scroll', function () {
					var scrollPosition = $(document).scrollTop();

					var donors = $('.donor');
					donors.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 900) {
							$(this).addClass("active");
						}
					});

				});
			}
		},

		// Resources
		'page_template_template_resources': {
			init: function () {

			},
			finalize: function () {
				$(window).on('scroll', function () {
					var scrollPosition = $(document).scrollTop();

					var items = $('.load-item');
					items.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 900) {
							$(this).addClass("active");
						}
					});

				});
			}
		},

		// Financials
		'page_template_template_financials': {
			init: function () {

			},
			finalize: function () {
				$(window).on('scroll', function () {
					var scrollPosition = $(document).scrollTop();

					var reports = $('.report');
					reports.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 900) {
							$(this).addClass("active");
						}
					});

				});
			}
		},

		// About
		'page_template_template_about': {
			init: function () {

			},
			finalize: function () {
				var prev = [];
				if ($(document).scrollTop() >= ($(".timeline-line").offset().top - 800)) {
					prev["timelineHeight"] = $(document).scrollTop() - ($(".timeline-line").offset().top - 800);
					$(".timeline-line").height(prev["timelineHeight"]);
				} else {
					prev["timelineHeight"] = 0;
				}
				$(window).on('scroll', function () {
					var scrollPosition = $(document).scrollTop();
					//Timeline Line Effect
					var timelineTop = $(".timeline-line").offset().top;
					if (scrollPosition >= (timelineTop - 500)) {
						var timelineHeight = (scrollPosition - (timelineTop - 400)) * 2;
						var timelineStop = $(".year").last().offset().top;
						if (timelineHeight > prev["timelineHeight"] && timelineHeight <= ($(".timeline").height() + 400)) {
							if ((timelineTop + timelineHeight) >= timelineStop) {
								timelineHeight = timelineHeight - ((timelineTop + timelineHeight) - timelineStop);
							}
							$(".timeline-line").height(timelineHeight);
							prev["timelineHeight"] = timelineHeight;
						}
					}
					//Fade In History Events
					var years = $('.year');
					years.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 600) {
							$(this).addClass("active-year");
						}
					});

					// Fade in side images
					var images = $('.side-image');
					images.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 500) {
							$(this).addClass("active");
						}
					});

					// fade in red squares
					var squares = $('.red-square-timeline');
					squares.each(function () {
						if (scrollPosition >= ($(this).offset().top) - 700) {
							$(this).addClass("active");
						}
					});
				});
			}
		}

	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = funcname === undefined ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);
})(window.jQuery, window.Clique); // Fully reference jQuery after this point.

jQuery.expr[":"].contains = jQuery.expr.createPseudo(function(arg) {
    return function( elem ) {
        return jQuery(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
    };
});
